import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import LanguagePicker from "./languagePicker"

import logoRegular from "../images/gloria/gloria-logo.svg"
import logoWhite from "../images/gloria/gloria-logo-white.svg"
import SideHamburgerMenu from "./sideHamburgerMenu"

const NavWrapper = styled.div`
  position: ${(props) => props.theme.position};
  z-index: 10;
  top: 0;
  left: 0;
  height: 90px;
  margin-bottom: -90px;
    margin-bottom: -90px;
  width: 100%;
  transition: height 0.3s ease-in-out;

  & .background {
    width: 100%;
    height: 100%;
    background: #ffffff;
    top: 0;
    transition: top 0.3s ease-out;
    position: absolute;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  }

  & .logo {
    position: absolute;
    top: 9px;
    height: 150%;
    width: auto;
    left: 10px;
    transition: all 0.3s ease-in-out;
  }

  & .logo.regular {
    opacity: 1;
  }

  & .logo.white {
    opacity: 0;
  }

  &.hidden {
    height: 70px;
    margin-bottom: -70px;

    .background {
      top: -100%;
      transition: top 0.3s ease-in;
    }

    .logo {
      top: -5px;
      left: -25px;

      filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.7));
    }

    .logo.white {
      opacity: 1;
    }

    .logo.regular {
      opacity: 0;
    }
  }
  @media (max-width: 600px) {
    height: 55px;
    margin-bottom: -55px;
    .logo {
      top: 5px;
      left: 5px;
      z-index: 110;
    }

    .spacer {
      height: 50px;
    }

    &.hidden {
      height: 55px;
    margin-bottom: -55px;
      .logo {
        top: 5px;
        left: -35px;
      }
    }
  }
`

const Spacer = styled.div`

      height: 70px;
  @media (max-width: 600px) {
    
      height: 50px;
  }
`

NavWrapper.defaultProps = {
  theme: {
    position: "fixed",
  },
}

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hidden: false }
    this.fixed = props.fixed || false
  }
  hide() {
    if (this.fixed) return
    this.setState({ hidden: true })
  }

  show() {
    if (this.fixed) return
    this.setState({ hidden: false })
  }
  render() {
    console.log(this.props.fixed)
    return (
      <>
        <NavWrapper className={this.state?.hidden ? "hidden" : ""} fixed={this.props.fixed || false} theme={{position:"relative"}}>
          <div className={"background"}>
            {/* <div style={{ position: "absolute", left: "auto", right: "50px" }}>
              <LanguagePicker
                options={[
                  { value: "hrv", label: "H" },
                  { value: "eng", label: "E" },
                ]}
                value={"hrv"}
              />
            </div> */}
          </div>
          <Link to="/">
            <img src={logoRegular} className="logo regular" alt="Logo" />
            <img src={logoWhite} className="logo white" alt="Logo" />
          </Link>
        </NavWrapper>
        <SideHamburgerMenu hidden={this.state.hidden} />
        {this.props.fixed && <Spacer/>}
      </>
    )
  }
}

export default Nav
