import React from "react"
// import { ButtonGroupPicker, ButtonOption } from "react-rainbow-components"

class LanguagePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value || "" }
    this.handleOnChange = this.handleOnChange.bind(this)

    if (props.onChange) this.onChangeCallback = props.onChange
  }

  handleOnChange(value) {
    this.setState({ value })
    if (this.onChangeCallback) this.onChangeCallback(value)
  }

  render() {
    const { value } = this.state
    return (
      <div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
        {/* <ButtonGroupPicker
          id={this.props.id || "language-picker-component"}
          value={value}
          onChange={this.handleOnChange}
          name="picker"
          size="small"
        >
          {this.props.options &&
            this.props.options.map((option) => (
              <ButtonOption
                label={option.label || "label"}
                name={option.value || "value"}
              />
            ))}
        </ButtonGroupPicker> */}
      </div>
    )
  }
}

export default LanguagePicker
