import {
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core/styles"
import orange from "@material-ui/core/colors/orange"
import red from "@material-ui/core/colors/red"

let mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#a90000",
    }
  },
  typography: {
    fontFamily: ['"Merriweather Sans"', "sans-serif"].join(","),
    h6: {
      lineHeight: 1.25
    }
  },
})
// mainTheme = responsiveFontSizes(mainTheme);

let secondaryTheme = createMuiTheme({
  ...mainTheme,
  palette: {
    ...mainTheme.palette,
    text: {
      primary: "#fff",
    },
  },
})
// secondaryTheme = responsiveFontSizes(secondaryTheme);

export {
  mainTheme,
  secondaryTheme
}