import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import { elastic as Menu } from 'react-burger-menu'
import '../assets/css/hamburgerStyle.css'
import { Typography, ThemeProvider } from '@material-ui/core'
import { secondaryTheme } from '../utils/theme'

const createLink = (name, to) => ({ name, to })
const navLinks = [
  createLink('Naslovna', '/'),
  createLink("Tečajevi","/tecajevi"),
  createLink("Dječja igraonica","/igraonica"),
  createLink("Prevođenje","/prijevodi"),
  createLink('O nama', '/onama'),
  createLink('Kontakt', '/kontakt'),
  createLink('Cjenik usluga', '/cjenik'),
]
class SideHamburgerMenu extends React.Component {
  render() {
    return (
      <ThemeProvider theme={secondaryTheme}>
        <Menu
          right
          width={'380px'}
          disableAutoFocus
          burgerButtonClassName={
            this.props?.hidden ? 'bm-burger-button-hidden' : 'bm-burger-button'
          }
        >
          {navLinks.map(({ name, to }) => (
            <Typography variant="h5">
              <Link to={to} style={{ textDecoration: 'none' }}>
                {name}
              </Link>
            </Typography>
          ))}
        </Menu>
      </ThemeProvider>
    )
  }
}

export default SideHamburgerMenu
